import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import './style.scss';
import './ekka.scss';
import SideBar from './sidebar';
import { Select, Form, Upload, Button,Modal, notification } from "antd";
import Header from './Header';
import EditBannerPopup from './EditBannerPopup';
import { UploadOutlined } from '@ant-design/icons';

const menuItems = [
  { text: "Dashboard", icon: "mdi mdi-view-dashboard-outline", link: "index" },
  // Add more menu items as needed
];

const SubSubCategory = () => {
  const token = localStorage.getItem('token');
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const handleToggleDropdown = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };
  const [categories, setCategories] = useState([]);
  const [loading, setLoading] = useState(true);
  const navigate = useNavigate();
  const [url, setURL] = useState('');
  const [title, setTitle] = useState('');
  const [description, setDescription] = useState('');
  const [fulldescription, setFulldescription] = useState('');
  const [producttag, setProducttag] = useState('');
  const [data, setData] = useState([]);
  const [fileList, setFileList] = useState([]); // To store uploaded files
  const [file, setFile] = useState([]);

  const [showEditModal, setShowEditModal] = useState(false); // State to manage the edit modal visibility
  const [selectedCategory, setSelectedCategory] = useState(null); // State to store the selected category for editing
  const [selectedBanner, setSelectedBanner] = useState(null); // State to store the selected banner for editing
  const [showEditPopup, setShowEditPopup] = useState(false); // State to manage the edit popup visibility
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [selectedBannerForDeletion, setSelectedBannerForDeletion] = useState(null);
  // Function to open the edit popup
  const handleEditBanner = (banner) => {
    setSelectedBanner(banner);
    setShowEditPopup(true);
  };
  // Function to close the edit popup
  const handleCloseEditPopup = () => {
    setSelectedBanner(null);
    setShowEditPopup(false);
  };

  // Function to open the edit modal
  const handleEditCategory = (category) => {
    setSelectedCategory(category);
    setShowEditModal(true);
  };

  // Function to close the edit modal
  const handleCloseEditModal = () => {
    setSelectedCategory(null);
    setShowEditModal(false);
  };



  useEffect(() => {
    if (!token) {
      // Redirect to the login page if the token is not present
      navigate('/AdminLogin');
      return;
    }
    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once

  const fetchData = async () => {
    try {
      const response = await axios.put("https://api.nagaratharcoc.com/api/v1/banners/get", {}, {

        headers: {
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        navigate('/AdminLogin');
        return; // Exit early to prevent further logic execution
      }
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onFinish = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior
    const token = localStorage.getItem('token');
    const formData = new FormData();
    formData.append("file", file);
    formData.append("url", url);
    formData.append("title", title);
    formData.append("description", description);
	formData.append("section", "1");

    try {
      setLoading(false);
      // TODO: Replace with actual API call
      const response = await fetch("https://api.nagaratharcoc.com/api/v1/banners/add", {
        method: "POST",
        body:
          formData,
        headers: {
          'Authorization': 'Bearer ' + token,
          //   'Content-Type': 'application/json',
        },
      });

      //   if (response.status === 401 || response.status === 404) {
      // 	// Redirect to the login page for 401 or 404 errors
      // 	navigate('/login');
      // 	return; // Exit early to prevent further logic execution
      //   }

      if (response.ok) {
        const data = await response.json();
        const token = data.token;
        console.log("POST request successful");
        if (response.data && response.data.length > 0) {
          setData(response.data);
        } else {
          setData([]); // If no data is received, set an empty array
        }
        setLoading(false);
        window.location.reload(); // Refresh the page
        if (data.token) {
          localStorage.setItem('token', token);
        }
        // Optionally, you can update your component state or perform other actions here
      } else {
        console.error("POST request failed");
      }
    } catch (error) {
      console.error("Error posting data:", error);
      setLoading(false);
    }
  };

  const handleFileUpload = (options) => {
    const { file } = options; // Extract the selected file from options
    const maxFileSize = 1024 * 1024;
		if (file.size > maxFileSize) {
			notification.error({
				message: 'Please upload an image with a size less than 1MB.',
			});
			return;
		}
    setFile(file); // Store the selected file in the state
  };


const handleDeleteCategory = async (bannerid) => {
  try {
    setLoading(true);

    // Set the selected category for deletion
    const selectedBanner = data.find((category) => category.bannerid === bannerid);
    setSelectedBannerForDeletion(selectedBanner);

    // Show the delete confirmation modal
    setShowDeleteModal(true);
  } catch (error) {
    console.error('Error preparing delete:', error);
    setLoading(false);
  }
};
const handleDeleteCategoryConfirmed = async () => {
  try {
    // Make an API request to delete the category
    const response = await axios.post(
      `https://api.nagaratharcoc.com/api/v1/banners/delete`,
      {
        bannerid: selectedBannerForDeletion.bannerid,
      },
      {
        headers: {
          Authorization: 'Bearer ' + localStorage.getItem('token'),
          'Content-Type': 'application/json',
        },
      }
    );

    if (response.status === 200) {
      // Refresh the page or update the banner list
      fetchData();
      window.location.reload();
    } else {
      console.error('Failed to delete banner');
    }
  } catch (error) {
    console.error('Error deleting banner:', error);
  } finally {
    // Clear the selected category and hide the modal
    setSelectedBannerForDeletion(null);
    setShowDeleteModal(false);
  }
};
  return (
    <div className="wrapper">

      {/* <!-- LEFT MAIN SIDEBAR --> */}
      <SideBar />

      {/* <!-- Header --> */}
      <Header />

      {/* <!-- CONTENT WRAPPER --> */}
      <div className="ec-content-wrapper">
        <div className="content" style={{ marginTop: "50px" }}>
          <div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
            <h1>Banner</h1>
            <p className="breadcrumbs"><span><a href="index">Home</a></span>
              <span><i className="mdi mdi-chevron-right"></i></span>Banner</p>
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-12">
              <div className="ec-cat-list card card-default mb-24px">
                <div className="card-body">
                  <div className="ec-cat-form">
                    <h4>Add New Banner</h4>

                    <form
                      onSubmit={onFinish}
                    >

                      <div className="col-md-12">
                        <label className="form-label" name="uploadfile">Upload Images</label>
                        <Form.Item
                          name="uploadfile"
                        >
                          <Upload
                            customRequest={handleFileUpload} // Use the customRequest prop for custom upload handling

                          >
                            <Button icon={<UploadOutlined />} style={{ top: '0px', borderRadius: '0px' }}>Click to Upload</Button>
                          </Upload>
                        </Form.Item>
                        {/* <Upload
														customRequest={handleFileUpload} // Use the customRequest prop for custom upload handling
														fileList={fileList}
														onChange={({ fileList }) => setFileList(fileList)}
														>
											<Button icon={<UploadOutlined />} style={{top:'0px',borderRadius:'0px'}}>Click to Upload</Button>
										</Upload> */}
                      </div>
                      <div className="col-md-12">
                        <label className="form-label" name="title">URL</label>
                        <input id="title" name="title" className="form-control here set-slug" type="text" value={url} onChange={(e) => setURL(e.target.value)} autoComplete="off" />
                      </div>
                      <div className="col-md-12">
                        <label className="form-label" name="title">Title</label>
                        <input id="title" name="title" className="form-control here set-slug" type="text" value={title} onChange={(e) => setTitle(e.target.value)} autoComplete="off"  />
                      </div>
                      <div className="col-md-12">
                        <label for="description" className="col-12 col-form-label">Description</label>
                        <div className="col-12">
                          <input id="description" name="description" className="form-control here set-slug" type="text" value={description} onChange={(e) => setDescription(e.target.value)} autoComplete="off"  />
                        </div>
                      </div>

                      <div className="row">
                        <div className="col-12">
                          <button name="submit" type="submit" className="btn btn-primary">Submit</button>
                        </div>
                      </div>

                    </form>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-12">
              <div className="ec-cat-list card card-default">
                <div className="card-body">
                  <div className="table-responsive">
                    <table id="responsive-data-table" className="table">
                      <thead>
                        <tr>
                          {/* <th>Thumb</th> */}
                          <th>Image</th>
                          <th>URL</th>
                          <th>Title</th>
                          <th>Description</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((category) => (
                          <tr key={category.bannerid}>
                            <td><img src={category.file} alt="category.bannerid" width="280" height="215" /></td>
                            <td>{category.url}</td>
                            <td>{category.title}</td>
                            <td>{category.description}</td>
                            <td>
                              <div className="btn-group">
                              <a
                                  href="#"
                                  className="btn btn-outline-success"
                                  onClick={(e) => {
                                    e.preventDefault(); // Prevent the anchor tag from navigating
                                    handleEditBanner(category); // Use 'category' instead of 'banner'
                                  }}
                                >
                                  Edit
                                </a> 
                                <a
                                  href="#"
                                  className="btn btn-outline-success"
                                  onClick={(e) => {
                                    e.preventDefault(); // Prevent the anchor tag from navigating
                                    handleDeleteCategory(category.bannerid); // Use 'category' instead of 'banner'
                                  }}
                                >
                                  Delete
                                </a>
                                {/* Add your delete logic here */}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>

                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <Modal
        visible={showDeleteModal}
        title="Confirm Deletion"
        onCancel={() => setShowDeleteModal(false)}
        onOk={handleDeleteCategoryConfirmed}
        okText="Delete"
      >
        <p>Are you sure you want to delete this banner?</p>
      </Modal>


      {selectedBanner && (
        <EditBannerPopup
        show={showEditPopup}
        onHide={() => setShowEditPopup(false)}
        category={selectedBanner}
      />
      )}

    </div>
  );
}

export default SubSubCategory;
