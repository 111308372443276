// EditCategoryModal.js
import React, { useState, useEffect } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import axios from 'axios';
import { useNavigate } from 'react-router-dom';
import { Select, DatePicker  } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
const { Option } = Select;
const EditCustomer = ({
  category,
  show,
  onHide,
  onSave,
  isLoadingEdit,
  editChitDetails,
}) => {
  const navigate = useNavigate();
  const [loading, setLoading] = useState(false);

  // Define state variables for form fields
  const [firstname, setFirstName] = useState(category.firstname);
  const [idno, setIdno] = useState(category.idno);
  const [email, setEmail] = useState(category.email);
  const [mobile, setMobile] = useState(category.mobile);
  const [memberTypes, setMemberTypes] = useState([]);
  const [selectedMemberType, setSelectedMemberType] = useState(category.membertype);
  useEffect(() => {
    const fetchMemberTypes = async () => {
      try {
        const response = await axios.put('https://api.nagaratharcoc.com/api/v1/membertype/get');
        setMemberTypes(response.data);
      } catch (error) {
        console.error('Error fetching member types:', error);
      }
    };

    fetchMemberTypes();
  }, []);
  useEffect(() => {
    // Set the initial selected member types when the modal is opened for editing
    if (show && category) {
      setSelectedMemberType(category.assignedmembertype.map((type) => type.membertype));
    }
  }, [show, category]);
  const handleSaveChanges = async () => {
    // Create an object with the updated values
    const updatedCategory = {
      clientid: category.clientid, // Include the category ID for identification
      idno,
      firstname,
      email,
      mobile,
      membertype: selectedMemberType,
       };
  
    try {
      setLoading(true);
  
      // Make an API request to update the category without uploading the image
      const response = await axios.post(
        "https://api.nagaratharcoc.com/api/v1/client/editClientDetails",
        updatedCategory,
        {
          headers: {
            Authorization: "Bearer " + localStorage.getItem("token"),
            "Content-Type": "application/json",
          },
        }
      );
  
      if (response.status === 401 || response.status === 404) {
        navigate("/AdminLogin");
        return;
      }
  
      if (response.data.success) {
        console.log("Category updated successfully");
        onSave(updatedCategory); // Notify the parent component about the update
        onHide(); // Close the modal
      } else {
        console.error("Failed to update category");
      }
  
      setLoading(false);
      // window.location.reload(); // Refresh the page
    } catch (error) {
      console.error("Error updating category:", error);
      setLoading(false);
    }
  };
  
    

  return (
    <Modal show={show} onHide={onHide}>
      {/* Modal content */}
      <Modal.Header closeButton>
        <Modal.Title>Edit Client Details</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <Form>
        <Form.Group controlId="categoryname">
            <Form.Label>Member ID</Form.Label>
            <Form.Control
              type="text"
              name="categoryname"
              value={idno}
              onChange={(e) => setIdno(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="categoryname">
            <Form.Label>Name</Form.Label>
            <Form.Control
              type="text"
              name="categoryname"
              value={firstname}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </Form.Group>

          <Form.Group controlId="slug">
            <Form.Label>Email</Form.Label>
            <Form.Control
              type="text"
              name="slug"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </Form.Group>
         


          <Form.Group controlId="sortdescription">
            <Form.Label>Phone No.</Form.Label>
            <Form.Control
              as="textarea"
              name="sortdescription"
              value={mobile}
              onChange={(e) => setMobile(e.target.value)}
            />
          </Form.Group>
          <Form.Group controlId="membertype">
  <Form.Label>Member Type</Form.Label>
  <Select
    mode="multiple"
    style={{ width: '100%' }}
    placeholder="Select Member Types"
    value={selectedMemberType}
    onChange={(selectedValues) => setSelectedMemberType(selectedValues)}
    dropdownStyle={{ zIndex: 1056 }}
  >
    {memberTypes.map((type) => (
      <Option key={type.membertypeid} value={type.membertype}>
        {type.membertype}
      </Option>
    ))}
  </Select>
</Form.Group>


        </Form>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={onHide}>
          Close
        </Button>
        <Button variant="primary" onClick={handleSaveChanges} disabled={loading}>
          Save Changes
        </Button>
        
      </Modal.Footer>
    </Modal>
  );
};

export default EditCustomer;
