import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import './style.scss';
import './ekka.scss';
import SideBar from './sidebar';
import {Select, Form, Upload, Button ,DatePicker } from "antd";
import Header from './Header';
import EditOffer from './EditOffer';
import { UploadOutlined } from '@ant-design/icons';

const menuItems = [
	{ text: "Dashboard", icon: "mdi mdi-view-dashboard-outline", link: "index" },
	// Add more menu items as needed
  ];


  const Offer = () => {
	const token = localStorage.getItem('token');
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const handleToggleDropdown  = () => {
		setIsDropdownOpen(!isDropdownOpen);
	  };
	  const [loading, setLoading] = useState(true);
	  const navigate = useNavigate();
	  
	  const [companycategory, setCompanycategory] =useState('');
	  const [data, setData] = useState([]);
	  const [showEditModal, setShowEditModal] = useState(false); // State to manage the edit modal visibility
	  const [selectedCategory, setSelectedCategory] = useState(null); // State to store the selected category for editing
	
	  
	  // Function to open the edit modal
	  const handleEditCategory = (category) => {
		setSelectedCategory(category);
		setShowEditModal(true);
	  };
	
	  const handleCloseEditModal = () => {
		setSelectedCategory(null);
		setShowEditModal(false);
	  };
	  const handleSaveEdit = (editedData) => {
		console.log('Edited data:', editedData);
		handleCloseEditModal();
	  };
	  useEffect(() => {
		if (!token) {
		  // Redirect to the login page if the token is not present
		  navigate('/AdminLogin');
		  return;
		}
		fetchData();
	  }, []); // Empty dependency array ensures the effect runs only once
	  const fetchData = async () => {
		try {
		  const response = await axios.put("https://api.nagaratharcoc.com/api/v1/companycategory/get",{},{
			
		  headers: {
			'Content-Type': 'application/json',
		  },
		  });
		  if (response.status === 401 || response.status === 404) {
			// Redirect to the login page for 401 or 404 errors
			navigate('/login');
			return; // Exit early to prevent further logic execution
		  }
		  setData(response.data);
		} catch (error) {
		  console.error("Error fetching data:", error);
		}
	  };

	  const onFinish = async (e) => {
		e.preventDefault(); // Prevent the default form submission behavior
		const token = localStorage.getItem('token');
		try {
		  setLoading(true);
		  // Construct the JSON payload
		  const payload = {
			companycategory: companycategory,
		  };
	  
		  // TODO: Replace with actual API call
		  const response = await fetch("https://api.nagaratharcoc.com/api/v1/companycategory/add", {
			method: "POST",
			body: JSON.stringify(payload),
			headers: {
			  'Authorization': 'Bearer ' + token,
			  'Content-Type': 'application/json',
			},
		  });
	  
		  if (response.ok) {
			const data = await response.json();
			console.log("POST request successful");
	  
			if (data && data.length > 0) {
			  setData(data);
			} else {
			  setData([]);
			}
	  
			setLoading(false);
			window.location.reload(); // Refresh the page
	  
			if (data.token) {
			  localStorage.setItem('token', data.token);
			}
		  } else {
			console.error("POST request failed");
		  }
		} catch (error) {
		  console.error("Error posting data:", error);
		  setLoading(false);
		}
	  };
	  
	  const handleDeleteCategory = async (companycategoryid) => {
		const confirmed = window.confirm("Are you sure you want to delete this business category?");

		if (!confirmed) {
		  // If the user cancels, do nothing
		  return;
		}
		try {
		  setLoading(true);
		
		  // Make an API request to delete the category
		  const response = await axios.post(
		  `https://api.nagaratharcoc.com/api/v1/companycategory/delete`,
		  {
			companycategoryid: companycategoryid, // Pass the categoryId as a payload
		  },
		  {
			headers: {
			Authorization: 'Bearer ' + localStorage.getItem('token'),
			'Content-Type': 'application/json',
			},
		  }
		  );
		  if (response.status === 200) {
		  // Refresh the page
		  window.location.reload();
		  } else {
		  console.error('Failed to delete Common Info');
		  }
		
		  // Rest of the code remains the same
		  // ...
		} catch (error) {
		  console.error('Error deleting Common Info:', error);
		  setLoading(false);
		}
		};

	return (
		<div className="wrapper">

		{/* <!-- LEFT MAIN SIDEBAR --> */}
		<SideBar/>


			{/* <!-- Header --> */}
			<Header/>

			{/* <!-- CONTENT WRAPPER --> */}
			<div className="ec-content-wrapper">
				<div className="content" style={{marginTop:"50px"}}>
					<div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
							<h1>Business Category</h1>
							<p className="breadcrumbs"><span><a href="index">Home</a></span>
								<span><i className="mdi mdi-chevron-right"></i></span>Business Category</p>
					</div>
					<div className="row">
						<div className="col-xl-4 col-lg-12">
							<div className="ec-cat-list card card-default mb-24px">
								<div className="card-body">
									<div className="ec-cat-form">
										<h4>Add New Business Category</h4>

										<form
										onSubmit={onFinish}
										>
                                                <div className="col-md-12">
                                                <label className="form-label" name="title">Business Category</label>
                                                <input id="title" name="title" className="form-control here set-slug" type="text" value={companycategory} onChange={(e) => setCompanycategory(e.target.value)}/>
                                                </div>
											<div className="row">
												<div className="col-12">
													<button name="submit" type="submit" className="btn btn-primary">Submit</button>
												</div>
											</div>

										</form>

									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-8 col-lg-12">
							<div className="ec-cat-list card card-default">
								<div className="card-body">
									<div className="table-responsive">
                                    <table id="responsive-data-table" className="table">
											<thead>
												<tr>
													{/* <th>Thumb</th> */}
													<th>Business Category</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
                        {data.map((category) => (
                          <tr key={category.companycategoryid}>
                            <td>{category.companycategory}</td>
                            <td>
							<div className="btn-group">
     <a
      href="#"
      className="btn btn-outline-success"
      onClick={(e) => {
        e.preventDefault(); // Prevent the anchor tag from navigating
        handleEditCategory(category);
      }}
    >
      Edit
    </a> 
     <a
      href="#"
      className="btn btn-outline-danger"
      onClick={(e) => {
        e.preventDefault(); // Prevent the anchor tag from navigating
        handleDeleteCategory(category.companycategoryid);
      }}
    >
      Delete
    </a> 
  </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>

										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
			</div> 


			{selectedCategory && (
  <EditOffer
    category={selectedCategory}
    show={showEditModal} // Pass the value of showEditModal as isModalVisible
    onHide={handleCloseEditModal}
    onSave={handleSaveEdit}
	fetchData={fetchData}
  />
)}


	</div>
  );
}

export default Offer;
