import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import './style.scss';
import './ekka.scss';
import SideBar from './sidebar';
import { Input } from 'antd';
import Header from './Header';
import axios from "axios";
import { Select, Form, Upload, Button } from "antd";
import { UploadOutlined, CloseCircleOutlined } from '@ant-design/icons';
import ReactQuill from 'react-quill';
import 'react-quill/dist/quill.snow.css';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';

const ProductEdit = () => {
    const navigate = useNavigate();
    const [password, setPassword] = useState('');
    const [confirmpassword, setConfirmPassword] = useState('');
    const [data, setData] = useState([]);
    const [passwordsMatch, setPasswordsMatch] = useState(true);
    const [resetMessage, setResetMessage] = useState("");

    useEffect(() => {
        const fetchClientDetails = async () => {
            try {
                const response = await fetch(
                    'https://api.nagaratharcoc.com/api/v1/login/getuserdetails',
                    {
                        method: 'POST',
                        headers: {
                            Authorization: `Bearer ${localStorage.getItem('token')}`,
                        },
                    }
                );

                const responseData = await response.json();
                setData(responseData.id);
            } catch (error) {
                console.error("Error checking authentication:", error);
            }
        };

        fetchClientDetails();
    }, []);

    const handleNewPasswordChange = (e) => {
        const password = e.target.value;
        setPassword(password);
        setPasswordsMatch(password === confirmpassword);
    };

    const handleConfirmPasswordChange = (e) => {
        const confirmpassword = e.target.value;
        setConfirmPassword(confirmpassword);
        setPasswordsMatch(password === confirmpassword);
    };

    const handleEdit = async (e) => {
        e.preventDefault();

        // Make a POST request to the editadmin API
        try {
            const response = await axios.post(
                'https://api.nagaratharcoc.com/api/v1/client/editadmin',
                {
                    employeeid: data, // Use the id obtained from the getuserdetails API
                    password: password,
                },
                {
                    headers: {
                        Authorization: `Bearer ${localStorage.getItem('token')}`,
                        'Content-Type': 'application/json',
                    },
                }
            );

            // Handle the response as needed
            console.log('Edit response:', response.data);
			setResetMessage(response.data.description);

            // Optionally, navigate to another page or handle success
        } catch (error) {
            console.error('Error editing admin:', error);
            // Handle the error as needed
        }
    };

    return (
        <div className="wrapper">
            <SideBar />
            <div className="ec-page-wrapper">
                <Header />
                <div className="ec-content-wrapper">
                    <div className="content" style={{ marginTop: "50px" }}>
                        <div className="breadcrumb-wrapper d-flex align-items-center justify-content-between">
                            <div>
                                <h1>Admin Password Change</h1>
                            </div>
                        </div>
                        <div className="row">
                            <div className="col-12">
                                <div className="card card-default">
                                    <div className="card-header card-header-border-bottom">
                                        {/* <h2>Add Product</h2> */}
                                    </div>

                                    <div className="card-body">
                                        <div className="col-lg-8">
                                            <div className="ec-vendor-upload-detail">
                                                <form onSubmit={handleEdit}>
                                                    <div className='row'>
                                                        <div className="col-md-6">
                                                            <label htmlFor="inputEmail4" className="form-label">Password</label>
                                                            <input type="password" className="form-control slug-title" id="inputEmailc" value={password} onChange={handleNewPasswordChange} />
                                                        </div>
                                                    </div>
                                                    <div className='row'>
                                                        <div className="col-md-6">
                                                            <label htmlFor="inputEmail4" className="form-label">Confirm password</label>
                                                            <input type="password" className="form-control slug-title" id="inputEmail4" value={confirmpassword} onChange={handleConfirmPasswordChange} />
                                                            {!passwordsMatch && (
                                                                <div className="password-mismatch-error">Passwords do not match.</div>
                                                            )}
                                                        </div>
                                                    </div>
                                                    <div className="col-md-12">
                                                        <button type="submit" className="btn btn-primary">Edit</button>
                                                    </div>
													{resetMessage && (
                <div className="reset-message">{resetMessage}</div>
            )}
                                                </form>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    );
}

export default ProductEdit;
