// EditSubCategory.js

import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, Upload, notification } from 'antd';
import { UploadOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import noImage from "./img/noimage.jpg";

const EditMemberType = ({
  show,
  onHide,
  category,
  fetchData,
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [file, setFile] = useState("");
  const [uploadedOrganiserId, setUploadedOrganiserId] = useState(null);
  const [file2, setProfilepic] = useState(category.file);
  useEffect(() => {
    if (show) {
      form.setFieldsValue(category);  // Assuming iconimage is part of the category data
    }
  }, [show, category, form]);

 

  const handleFileUpload = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        "https://api.nagaratharcoc.com/api/v1/common/file",
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onProgress({ percent: percentCompleted });
          },
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 401 || response.status === 404 || !token) {
        navigate('/login');
        return;
      }

      if (response.status === 200) {
        const uploadedId = response.data.organiserId;
        setUploadedOrganiserId(uploadedId);
        onSuccess("File uploaded");
      } else {
        onError("File upload failed");
      }
    } catch (error) {
      onError("File upload failed");
    }
  };

  const handleSave = async () => {
    try {
      const values = await form.validateFields();
      Modal.confirm({
        title: 'Confirm',
        content: 'Are you sure you want to save changes?',
        onOk: async () => {
      const requestData = {
        membertype: values.membertype,
        membertypeid: category.membertypeid,
        position: parseInt(values.position),
      };

      const response = await axios.post(
        "https://api.nagaratharcoc.com/api/v1/membertype/edit",
        requestData,
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 401 || response.status === 404 || !token) {
        navigate('/login');
        return;
      }

      if (response.status === 200) {
        // Handle successful save
        console.log("Event edited successfully");
        
      notification.success({
        message: response.data.description,
      });
      onHide();
      fetchData();
      } else {
        // Handle save failure
        console.error("Failed to edit event");
      }
    },
      onCancel: () => {
        // User clicked Cancel
        console.log('Save canceled');
      },
    });
    } catch (error) {
      // Handle validation errors or other issues
      console.error("Error during form submission", error);
    }
  };

  return (
    <Modal
      title="Edit Member Type"
      visible={show}
      onCancel={onHide}
      footer={[
        <Button key="cancel" onClick={onHide}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSave} >
          Save
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="membertype" label="membertype">
          <Input />
        </Form.Item>
        <Form.Item name="position" label="Position">
          <Input />
        </Form.Item>
      </Form>
    </Modal>
  );
};



export default EditMemberType;
