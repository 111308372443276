// EditSubCategory.js

import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, Upload } from 'antd';
import { UploadOutlined, LoadingOutlined, PlusOutlined } from '@ant-design/icons';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import noImage from "./img/noimage.jpg";

const EditPlanDuration = ({
  show,
  onHide,
  category,
}) => {
  const [form] = Form.useForm();
  const navigate = useNavigate();
  const token = localStorage.getItem('token');
  const [file, setFile] = useState("");
  const [uploadedOrganiserId, setUploadedOrganiserId] = useState(null);
  const [file2, setProfilepic] = useState(category.iconimage);
  useEffect(() => {
    if (show) {
      form.setFieldsValue(category);  // Assuming iconimage is part of the category data
    }
  }, [show, category, form]);

 

  const handleFileUpload = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        "https://api.nagaratharcoc.com/api/v1/common/file",
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onProgress({ percent: percentCompleted });
          },
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 401 || response.status === 404 || !token) {
        navigate('/login');
        return;
      }

      if (response.status === 200) {
        const uploadedId = response.data.organiserId;
        setUploadedOrganiserId(uploadedId);
        onSuccess("File uploaded");
      } else {
        onError("File upload failed");
      }
    } catch (error) {
      onError("File upload failed");
    }
  };

  const handleSave = async () => {
    try {
      const confirmSave = window.confirm("Are you sure you want to save the changes to this Social Media Icon?");

      if (!confirmSave) {
        // If the user cancels, do nothing
        return;
      }
      const values = await form.validateFields();
      const requestData = {
        title: values.title,
        description: values.description,
        commoninfoid: category.commoninfoid,
        iconimage:uploadedOrganiserId,
      };

      const response = await axios.post(
        "https://api.nagaratharcoc.com/api/v1/common/edit",
        requestData,
        {
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'application/json',
          },
        }
      );

      if (response.status === 401 || response.status === 404 || !token) {
        navigate('/login');
        return;
      }

      if (response.status === 200) {
        // Handle successful save
        console.log("Event edited successfully");
        window.location.reload();
      } else {
        // Handle save failure
        console.error("Failed to edit event");
      }
    } catch (error) {
      // Handle validation errors or other issues
      console.error("Error during form submission", error);
    }
  };

  return (
    <Modal
      title="Edit SocialMedia Icon"
      visible={show}
      onCancel={onHide}
      footer={[
        <Button key="cancel" onClick={onHide}>
          Cancel
        </Button>,
        <Button key="save" type="primary" onClick={handleSave} >
          Save
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="title" label="Title">
          <Input />
        </Form.Item>
        <Form.Item name="description" label="description">
          <Input />
        </Form.Item>
        <Form.Item name="profilpic" label="PeofilePic">
          <img width={'20%'} height={'20%'} src={(file2 !=null ? file2 : noImage)} alt="profile-photo" class="img-fluid"/>
        </Form.Item>
        <Form.Item name="file" label="Upload Icon" valuePropName="file">
          <Upload
            customRequest={handleFileUpload}
            file={file}
            onChange={({ file }) => setFile(file)}
          >
            <Button icon={<UploadOutlined />} style={{ top: '0px', borderRadius: '0px' }}>Click to Upload</Button>
          </Upload>
        </Form.Item>
      </Form>
    </Modal>
  );
};



export default EditPlanDuration;
