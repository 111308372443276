import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import './style.scss';
import './ekka.scss';
import SideBar from './sidebar';
import { Select, Form, Upload, Button } from "antd";
import Header from './Header';
import EditMemberType from './EditMemberType';
import { UploadOutlined } from '@ant-design/icons';

const menuItems = [
  { text: "Dashboard", icon: "mdi mdi-view-dashboard-outline", link: "index" },
  // Add more menu items as needed
];

const Banner = () => {
  const token = localStorage.getItem('token');
  const navigate = useNavigate();
  const [membertype, setMembertype] = useState('');
  const [position, setPosition] = useState('');
  const [data, setData] = useState([]);

  const [showEditModal, setShowEditModal] = useState(false); // State to manage the edit modal visibility
  const [selectedCategory, setSelectedCategory] = useState(null); // State to store the selected category for editing
  const [selectedBanner, setSelectedBanner] = useState(null); // State to store the selected banner for editing
  const [showEditPopup, setShowEditPopup] = useState(false); // State to manage the edit popup visibility

  // Function to open the edit popup
  const handleEditBanner = (category) => {
    setSelectedCategory(category);
    setShowEditPopup(true);
  };

  // Function to close the edit popup
  const handleCloseEditPopup = () => {
    setSelectedCategory(null);
    setShowEditPopup(false);
  };

  // Function to open the edit modal
  const handleEditCategory = (category) => {
    setSelectedCategory(category);
    setShowEditModal(true);
  };

  // Function to close the edit modal
  const handleCloseEditModal = () => {
    setSelectedCategory(null);
    setShowEditModal(false);
  };

 // Function to save the edited data
// const handleSaveEdit = async (editedData) => {
//   try {
//     // Include the bannerid in the edited data object
//     editedData.membertypeid = selectedCategory.membertypeid;

//     // Make an API call to update the banner with the edited data
//     const response = await axios.post(
//       `https://api.nagaratharcoc.com/api/v1/membertype/edit`,
//       editedData,
//       {
//         headers: {
//           Authorization: 'Bearer ' + token,
//           'Content-Type': 'application/json',
//         },
//       }
//     );

//     if (response.status === 401 || response.status === 404) {
//       // Redirect to the login page for 401 or 404 errors
//       navigate('/login');
//       return;
//     }

//     if (response.data) {
//       // Update the banner data in the state with the edited data
//       setData((prevData) =>
//         prevData.map((category) =>
//         category.membertypeid === selectedCategory.membertypeid
//             ? { ...category, ...editedData }
//             : category
//         )
//       );

//       // Close the edit popup
//       handleCloseEditPopup();
//     }
//   } catch (error) {
//     console.error('Error updating banner:', error);
//   }
// };



  useEffect(() => {
    if (!token) {
      // Redirect to the login page if the token is not present
      navigate('/AdminLogin');
      return;
    }
    fetchData();
  }, []); // Empty dependency array ensures the effect runs only once

  const fetchData = async () => {
    try {
      const response = await axios.put("https://api.nagaratharcoc.com/api/v1/membertype/get", {}, {

        headers: {
          'Authorization': 'Bearer ' + token,
          'Content-Type': 'application/json',
        },
      });
      if (response.status === 401 || response.status === 404) {
        // Redirect to the login page for 401 or 404 errors
        navigate('/AdminLogin');
        return; // Exit early to prevent further logic execution
      }
      setData(response.data);
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const onFinish = async (e) => {
		e.preventDefault(); // Prevent the default form submission behavior
		const token = localStorage.getItem('token');


		try {
		  // TODO: Replace with actual API call
		  const response = await fetch("https://api.nagaratharcoc.com/api/v1/membertype/add", {
			method: "POST",
			headers: {
			  'Authorization': 'Bearer ' + token,
			  'Content-Type': 'application/json',
			},
			body: JSON.stringify({
        membertype,
        position:parseInt(position),
			}),
		  });
	  
		  if (response.status === 401 || response.status === 404) { 
			// Redirect to the login page for 401 or 404 errors
			// navigate('/AdminLogin');
			return; // Exit early to prevent further logic execution
		  }
	  
		  if (response.ok) {
			const data = await response.json();
			const token = data.token;
			console.log("POST request successful");
			if (response.data && response.data.length > 0) {
				setData(response.data);
			  } else {
				setData([]); // If no data is received, set an empty array
			  }
			window.location.reload(); // Refresh the page
			if (data.token) {
			  localStorage.setItem('token', token);
			}
			// Optionally, you can update your component state or perform other actions here
		  } else {
			console.error("POST request failed");
		  }
		} catch (error) {
		  console.error("Error posting data:", error);
		}
	  };
	  


  const [isSubMenuOpen, setIsSubMenuOpen] = useState({
    vendors: false,
    user: false,
    categories: false,
    products: false,
    orders: false,
    authentication: false,
    icons: false,
    otherpages: false,
  });

  const toggleSubMenu = (menuName) => {
    setIsSubMenuOpen((prevState) => ({
      ...prevState,
      [menuName]: !prevState[menuName],
    }));
  };

//Delete API

const handleDeleteCategory = async (membertypeid) => {
  const confirmed = window.confirm("Are you sure you want to delete this member type?");

  if (!confirmed) {
    // If the user cancels, do nothing
    return;
  }
  try {
    // Make an API request to delete the category
    const response = await axios.post(
    `https://api.nagaratharcoc.com/api/v1/membertype/delete`,
    {
      membertypeid: membertypeid, // Pass the categoryId as a payload
    },
    {
      headers: {
      Authorization: 'Bearer ' + localStorage.getItem('token'),
      'Content-Type': 'application/json',
      },
    }
    );
    if (response.status === 200) {
    // Refresh the page
    window.location.reload();
    } else {
    console.error('Failed to delete Common Info');
    }
  
    // Rest of the code remains the same
    // ...
  } catch (error) {
    console.error('Error deleting Common Info:', error);
  }
  };

  return (
    <div className="wrapper">

      {/* <!-- LEFT MAIN SIDEBAR --> */}
      <SideBar />

      {/* <!-- Header --> */}
      <Header />

      {/* <!-- CONTENT WRAPPER --> */}
      <div className="ec-content-wrapper">
        <div className="content" style={{ marginTop: "50px" }}>
          <div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
            <h1>Member Type</h1>
                {/* <p className="breadcrumbs"><span><a href="index">Home</a></span>
                  <span><i className="mdi mdi-chevron-right"></i></span>Member Type</p> */}
          </div>
          <div className="row">
            <div className="col-xl-4 col-lg-12">
              <div className="ec-cat-list card card-default mb-24px">
                <div className="card-body">
                  <div className="ec-cat-form">
                    <h4>Add Member Type</h4>

                    <form
                      onSubmit={onFinish}
                    >

                      <div className="col-md-12">
                        <label className="form-label" name="title">Member Type</label>
                        <input id="title" name="title" className="form-control here set-slug" type="text" value={membertype} onChange={(e) => setMembertype(e.target.value)} autoComplete="off"  />
                      </div>
                      <div className="col-md-12">
                        <label className="form-label" name="title">Position</label>
                        <input id="title" name="title" className="form-control here set-slug" type="number" value={position} onChange={(e) => setPosition(e.target.value)} autoComplete="off"  />
                      </div>
                      <div className="row">
                        <div className="col-12">
                          <button name="submit" type="submit" className="btn btn-primary">Submit</button>
                        </div>
                      </div>

                    </form>

                  </div>
                </div>
              </div>
            </div>
            <div className="col-xl-8 col-lg-12">
              <div className="ec-cat-list card card-default">
                <div className="card-body">
                  <div className="table-responsive">
                    <table id="responsive-data-table" className="table">
                      <thead>
                        <tr>
                          <th>Member Type</th>
                          <th>Action</th>
                        </tr>
                      </thead>
                      <tbody>
                        {data.map((category) => (
                          <tr key={category.membertypeid}>
                            <td>{category.membertype}</td>
                            <td>
                              <div className="btn-group">
                                <a
                                  href="#"
                                  className="btn btn-outline-success"
                                  onClick={(e) => {
                                    e.preventDefault(); // Prevent the anchor tag from navigating
                                    handleEditBanner(category); // Use 'category' instead of 'banner'
                                  }}
                                >
                                  Edit
                                </a>
                                <a
                                  href="#"
                                  className="btn btn-outline-success"
                                  onClick={(e) => {
                                    e.preventDefault(); // Prevent the anchor tag from navigating
                                    handleDeleteCategory(category.membertypeid); // Use 'category' instead of 'banner'
                                  }}
                                >
                                  Delete
                                </a>
                                {/* Add your delete logic here */}
                              </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>

                    </table>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>


      {selectedCategory && (
        <EditMemberType
        category={selectedCategory}
          show={showEditPopup}
          onHide={() => setShowEditPopup(false)}
          fetchData={fetchData}
        />
      )}

    </div>
  );
}

export default Banner;
