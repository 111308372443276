// EditSubCategory.js

import React, { useState, useEffect } from 'react';
import { Modal, Form, Input, Button, Upload,notification } from 'antd';
import { UploadOutlined, LoadingOutlined,PlusOutlined } from '@ant-design/icons';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import noImage from "./img/noimage.jpg";
import { CKEditor } from "@ckeditor/ckeditor5-react";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

const EditSubCategoryModal = ({
  show,
  onHide,
  onSave,
  categoryList,
  category,
}) => {
  const [form] = Form.useForm();
  const [loading, setLoading] = useState(false);
  const [organizerId, setOrganizerId] = useState(null);
	const navigate = useNavigate();
	const token = localStorage.getItem('token');
  const [uploading, setUploading] = useState(false);
  const [imageUrl, setImageUrl] = useState(null);
  const [file2, setProfilepic] = useState(category.eventimage);
  useEffect(() => {
    if (show) {
      form.setFieldsValue(category);
    }
  }, [show, category, form]);

  const handleSave = async () => {
    try {
      const confirmEdit = window.confirm("Are you sure you want to edit this event?");

      if (!confirmEdit) {
        // If the user cancels, do nothing
        return;
      }
      setLoading(true);
      const formData = await form.validateFields();
      
      // Update the category data with the necessary details for the edit API
      const editedData = {
        ...formData,
        eventid: category.eventid,
        categoryname: category.categoryname,
        fileid: organizerId,
        fileids : JSON.parse(sessionStorage.getItem('fileids') || '[]'),
      };

      // Call the onSave function with the editedData
      onSave(editedData);
    } catch (error) {
      console.error('Error saving data:', error);
    } finally {
      setLoading(false);
    }
  };

  const normFile = (e) => {
    if (Array.isArray(e)) {
      return e;
    }
    return e && e.fileList;
  };
  const handleFileUpload = async (options) => {
    const { onSuccess, onError, file, onProgress } = options;
    const maxFileSize = 1024 * 1024;if (file.size > maxFileSize) {
      notification.error({
          message: 'Please upload an image with a size less than 1MB.',
      });
      return;
  }
    const formData = new FormData();
    formData.append("file", file);

    try {
      const response = await axios.post(
        "https://api.nagaratharcoc.com/api/v1/fileupload/file",
        formData,
        {
          onUploadProgress: (progressEvent) => {
            const percentCompleted = Math.round(
              (progressEvent.loaded * 100) / progressEvent.total
            );
            onProgress({ percent: percentCompleted });
          },
          headers: {
            Authorization: 'Bearer ' + token,
            'Content-Type': 'multipart/form-data',
          },
        }
      );

      if (response.status === 401 || response.status === 404 || !token) {
        navigate('/AdminLogin');
        return;
      }

      setOrganizerId(response.data.organiserId);
      onSuccess("File uploaded");
    } catch (error) {
      onError("File upload failed");
    }
  };
  const getUploadButton = () => (
    <div>
      {uploading ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ marginTop: 8 }}>Upload</div>
    </div>
  );
  const handleDeleteImage = async (index) => {
    try {
      const updatedFileModel = [...category.files];
      const deletedFile = updatedFileModel[index];

      // Remove the deleted file from the session storage array
      const fileids = JSON.parse(sessionStorage.getItem('fileids') || '[]');
      const updatedFileIds = fileids.filter((fileid) => fileid !== deletedFile.fileid);
      sessionStorage.setItem('fileids', JSON.stringify(updatedFileIds));

      // Hit the API to delete the file, passing the fileid in the request body
      const response = await axios.post(
        'https://api.nagaratharcoc.com/api/v1/fileupload/delete',
        {
          fileid: deletedFile.fileid,
        },
        {
          headers: {
            Authorization: 'Bearer ' + localStorage.getItem('token'),
            'Content-Type': 'application/json',
          },
        }
      );
      

      if (response.status === 200) {
        // If the file is successfully deleted from the server, update the form fields
        form.setFieldsValue({ files: updatedFileModel });
        window.location.reload();
      } else {
        console.error('Failed to delete file. Status:', response.status);
      }
    } catch (error) {
      console.error('Error deleting file:', error);
    }
  };
  const beforeUpload = (file) => {
    const isImage = file.type.startsWith('image/');
    if (!isImage) {
      console.log('You can only upload image files!');
    }
    return isImage;
  };
  const customRequest = async ({ file, onSuccess, onError }) => {
    try {
      setUploading(true);
      const formData = new FormData();
      formData.append('file', file); // Change 'image' to 'file' here
      const response = await axios.post(
        'https://api.nagaratharcoc.com/api/v1/fileupload/file',
        formData,
        {
          headers: {
            'Content-Type': 'multipart/form-data',
            Authorization: 'Bearer ' + localStorage.getItem('token'),
          },
        }
      );
      setImageUrl(response.data.imageUrl);
      console.log(response.data.organiserId);
      const fileids = JSON.parse(sessionStorage.getItem('fileids') || '[]');
      fileids.push(parseInt(response.data.organiserId));
      sessionStorage.setItem('fileids', JSON.stringify(fileids));
      setUploading(false);
      onSuccess();
    } catch (error) {
      console.error('Error uploading image:', error);
      setUploading(false);
      onError();
    }
  };

  return (
    <Modal
      title="Edit Events"
      visible={show}
      onCancel={onHide}
      footer={[
        <Button key="cancel" onClick={onHide}>
          Cancel
        </Button>,
        <Button key="save" type="primary" loading={loading} onClick={handleSave}>
          Save
        </Button>,
      ]}
    >
      <Form form={form} layout="vertical">
        <Form.Item name="title" label="Title">
          <Input />
        </Form.Item>
        <Form.Item name="description" label="Description">
        <CKEditor
          editor={ClassicEditor}
          data={category.fulldetails}
          onChange={(event, editor) => {
            const data = editor.getData();
            form.setFieldsValue({ description: data});
          }}
        />
        </Form.Item>
        <Form.Item name="eventlink" label="Event Link">
          <Input.TextArea />
        </Form.Item>
        <Form.Item name="profilpic" label="Event Image">
          <img width={'75%'} height={'75%'} src={(file2 !=null ? file2 : noImage)} alt="profile-photo" class="img-fluid"/>
        </Form.Item>
        <Form.Item
          name="fileid"
          label="Upload Images"
          valuePropName="fileList"
          getValueFromEvent={normFile}
        >
          <Upload
            customRequest={(options) => handleFileUpload(options, token)}
            listType="picture"
          >
            <Button icon={<UploadOutlined />}>Click to Upload</Button>
          </Upload>
        </Form.Item>
        <Form.Item name="files" label="Event Images">
  {category.files &&
    category.files.map((file, index) => (
      <div key={index} style={{ marginBottom: '10px' }}>
        <img
          src={file.file}
          alt={`Product Image ${index + 1}`}
          style={{ width: '100px', height: '100px', objectFit: 'cover', marginRight: '10px' }}
        />
        <Button onClick={() => handleDeleteImage(index)}>Delete</Button>
      </div>
    ))}
  <Upload
    customRequest={customRequest}
    showUploadList={false}
    beforeUpload={beforeUpload}
  >
    {getUploadButton()}
  </Upload>
</Form.Item>
      </Form>
    </Modal>
  );
};

export default EditSubCategoryModal;
