import React, { useState, useEffect } from 'react';
import './style.scss';
import './ekka.scss';
import SideBar from './sidebar';
import Header from './Header';
import { useNavigate } from 'react-router-dom';
import { Modal, Button, Spin  } from 'antd';
import axios from "axios";
import {Select } from "antd";
import EditSubCategoryModal from './EditProduct';
const { confirm } = Modal;

const DeleteCategoryModal = ({ visible, onCancel, onConfirm }) => {
	return (
	  <Modal
		visible={visible}
		title="Confirm Deletion"
		onCancel={onCancel}
		footer={[
		  <Button key="cancel" onClick={onCancel}>
			Cancel
		  </Button>,
		  <Button key="delete" type="primary" onClick={onConfirm}>
			Delete
		  </Button>,
		]}
	  >
		<p>Are you sure you want to delete this pincode?</p>
	  </Modal>
	);
  };
const ProductAdd = () => {
	const token = localStorage.getItem('token');
	const [data, setData] = useState([]);
	const [loading, setLoading] = useState(true);
	const navigate = useNavigate();
	  const [country, setCountry] =useState('');
	  const [state, setState] =useState('');
	  const [pincode, setPincode] =useState('');
	  const [categoryList, setCategoriesList] = useState([]);
	  const [selectedCategoryId, setSelectedCategoryId] = useState('');
	  const [showDeleteModal, setShowDeleteModal] = useState(false);

	  const [showEditModal, setShowEditModal] = useState(false); // State to manage the edit modal visibility
	  const [selectedCategory, setSelectedCategory] = useState(null); // State to store the selected category for editing
	  
	  // Function to open the edit modal
	  const handleEditCategory = (category) => {
		setSelectedCategory(category);
		setShowEditModal(true);
	  };
	  const handleCloseEditModal = () => {
		setSelectedCategory(null);
		setShowEditModal(false);
	  };
	
	const handleDeleteCategoryConfirmed = async () => {
		try {
			
		  setLoading(true);
	
		  // Make an API request to delete the category
		  const response = await axios.post(
			`https://api.nagaratharcoc.com/api/v1/pincode/delete`,
			{
				pincodeid: selectedCategory,
			},
			{
			  headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
				'Content-Type': 'application/json',
			  },
			}
		  );
	
		  if (response.status === 200) {
			// Refresh the page or update the category list
			fetchData();
		  } else {
			console.error('Failed to delete category');
		  }
		} catch (error) {
		  console.error('Error deleting category:', error);
		} finally {
		  setLoading(false);
		  setShowDeleteModal(false);
		}
	  };
	  // Function to close the edit modal
	  const handleSaveEdit = async (editedData) => {
		try {
			const confirmResult = await new Promise((resolve) => {
				confirm({
				  title: 'Do you want to save the changes?',
				  onOk() {
					resolve(true);
				  },
				  onCancel() {
					resolve(false);
				  },
				});
			  });
		  
			  if (!confirmResult) {
				// User canceled the save
				return;
			  }
		  setLoading(true);
		  editedData.pincodeid = selectedCategory.pincodeid;
		  editedData.country = editedData.country;
      editedData.state = editedData.state;
      editedData.pincode = editedData.pincode;
		  // Make an API request to update the subcategory data
		  const response = await axios.post(
			`https://api.nagaratharcoc.com/api/v1/pincode/edit`,
			editedData,
			{
			  headers: {
				Authorization: 'Bearer ' + token,
				'Content-Type': 'application/json',
			  },
			}
		  );
	
		  if (response.status === 401 || response.status === 404) {
			// Redirect to the login page for 401 or 404 errors
			navigate('/login');
			return;
		  }
	
		  if (response.status === 200) {
			console.log('Subcategory updated successfully');
			window.location.reload();
			// Optionally, you can update your component state or perform other actions here
		  } else {
			console.error('PUT request failed');
		  }
		} catch (error) {
		  console.error('Error updating data:', error);
		} finally {
		  setLoading(false);
		  handleCloseEditModal(); // Close the modal whether the request succeeds or fails
		}
	  };

	  useEffect(() => {
			const fetchCategoryNames = async () => {
				try {
					const response = await axios.put("https://api.shopeasey.com/api/v1/category/get",{},{
					  headers: {
						'Authorization': 'Bearer ' +token,
						'Content-Type': 'application/json',
					  },
					});
					if (response.status === 401 || response.status === 404) {
					  // Redirect to the login page for 401 or 404 errors
					  navigate('/AdminLogin');
					  return; // Exit early to prevent further logic execution
					}
					setCategoriesList(response.data);
				} catch (error) {
					console.error("Error fetching names:", error);
				}
			};
	
			fetchCategoryNames();
		
	}, []);

	  useEffect(() => {
		if (!token) {
		  // Redirect to the login page if the token is not present
		  navigate('/AdminLogin');
		  return;
		}
		fetchData();
	  }, []); // Empty dependency array ensures the effect runs only once

    const first10Pincodes = data.slice(0, 10);
	  const fetchData = async () => {
		try {
		  const response = await axios.put("https://api.nagaratharcoc.com/api/v1/pincode/get",{},{
			
		  headers: {
			'Content-Type': 'application/json',
		  },
		  });
		  if (response.status === 401 || response.status === 404) {
			// Redirect to the login page for 401 or 404 errors
			navigate('/AdminLogin');
			return; // Exit early to prevent further logic execution
		  }
		  setData(response.data);
		  setLoading(false);
		} catch (error) {
		  console.error("Error fetching data:", error);
		  setLoading(false);
		}
	  };


	  const onFinish = async (e) => {
		e.preventDefault(); // Prevent the default form submission behavior
		const token = localStorage.getItem('token');
	  
		try {
		  setLoading(true);
		  // TODO: Replace with actual API call
		  const response = await fetch("https://api.nagaratharcoc.com/api/v1/pincode/add", {
			method: "POST",
			headers: {
			  'Authorization': 'Bearer ' + token,
			  'Content-Type': 'application/json',
			},
			body: JSON.stringify({
			country,
			  state,
			  pincode,
			}),
		  });
	  
		  if (response.status === 401 || response.status === 404) {
			// Redirect to the login page for 401 or 404 errors
			navigate('/AdminLogin');
			return; // Exit early to prevent further logic execution
		  }
	  
		  if (response.ok) {
			const data = await response.json();
			const token = data.token;
			console.log("POST request successful");
			if (response.data && response.data.length > 0) {
				setData(response.data);
			  } else {
				setData([]); // If no data is received, set an empty array
			  }
			setLoading(false);
			window.location.reload(); // Refresh the page
			if (data.token) {
			  localStorage.setItem('token', token);
			}
			// Optionally, you can update your component state or perform other actions here
		  } else {
			console.error("POST request failed");
		  }
		} catch (error) {
		  console.error("Error posting data:", error);
		  setLoading(false);
		}
	  };

	  const handleDeleteCategory = (categoryId) => {
		setSelectedCategory(categoryId);
		setShowDeleteModal(true);
	  };

    return(
        <div class="wrapper">

		{/* <!-- LEFT MAIN SIDEBAR --> */}
		<SideBar/>

		{/* <!-- PAGE WRAPPER --> */}
		<div class="ec-page-wrapper">

			{/* <!-- Header --> */}
			<Header/>

			{/* <!-- CONTENT WRAPPER --> */}
			<div class="ec-content-wrapper">
				<div class="content" style={{marginTop:"50px"}}>
					<div class="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
						<h1>Sub Category</h1>
						<p class="breadcrumbs"><span><a href="index">Home</a></span>
							<span><i class="mdi mdi-chevron-right"></i></span>Pincodes</p>
					</div>
					{loading ? (
                        <div style={{ textAlign: 'center', paddingTop: '50px' }}>
                            <Spin size="large" />
                        </div>
                    ) : (
					<div class="row">
						<div class="col-xl-4 col-lg-12">
							<div class="ec-cat-list card card-default mb-24px">
								<div class="card-body">
									<div class="ec-cat-form">
										<h4>Add Pincodes</h4>

										<form
										onSubmit={onFinish}
										>
											

											<div class="form-group row">
												<label for="text" class="col-12 col-form-label">Country</label> 
												<div class="col-12">
													<input id="text" name="text" class="form-control here slug-title" type="text"value={country} onChange={(e) => setCountry(e.target.value)} autoComplete="off" />
												</div>
											</div>

											<div class="form-group row">
												<label for="slug" class="col-12 col-form-label">State</label> 
												<div class="col-12">
													<input id="slug" name="slug" class="form-control here set-slug" type="text"value={state} onChange={(e) => setState(e.target.value)} autoComplete="off" />
												</div>
											</div>

											<div class="form-group row">
												<label class="col-12 col-form-label">Pincode</label> 
												<div class="col-12">
                        <input id="pincode" name="pincode" class="form-control here set-slug" type="text"value={pincode} onChange={(e) => setPincode(e.target.value)} autoComplete="off" />
												</div>
											</div> 

											<div class="row">
												<div class="col-12">
													<button name="submit" type="submit" class="btn btn-primary">Submit</button>
												</div>
											</div>

										</form>

									</div>
								</div>
							</div>
						</div>
						<div class="col-xl-8 col-lg-12">
							<div class="ec-cat-list card card-default">
								<div class="card-body">
									<div class="table-responsive">
										<table id="responsive-data-table" class="table">
											<thead>
												<tr>
													<th>Country</th>
													<th>State</th>
													<th>Pincode</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
                        {first10Pincodes.map((category) => (
                          <tr key={category.categoryid}>
						  <td>{category.country}</td>
                            <td>{category.state}</td>
                            <td>{category.pincode}</td>
                            <td>
							<div className="btn-group">
    <a
      href="#"
      className="btn btn-outline-success"
      onClick={(e) => {
        e.preventDefault(); // Prevent the anchor tag from navigating
        handleEditCategory(category);
      }}
    >
      Edit
    </a>
     <a
      href="#"
      className="btn btn-outline-danger"
      onClick={(e) => {
        e.preventDefault(); // Prevent the anchor tag from navigating
        handleDeleteCategory(category.pincodeid);
      }}
    >
      Delete
    </a> 
  </div>                </td>
                          </tr>
                        ))}
                      </tbody>
										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					)}
				</div> 
			</div> 

			
			{selectedCategory && (
  <EditSubCategoryModal
  show={showEditModal}
  onHide={handleCloseEditModal}
  onSave={handleSaveEdit}
  categoryList={categoryList}
  category={selectedCategory}
/>
)}
{selectedCategory && (
            <DeleteCategoryModal
              visible={showDeleteModal}
              onCancel={() => setShowDeleteModal(false)}
              onConfirm={handleDeleteCategoryConfirmed}
            />
          )}
		</div> 

	</div> 
  
    );
}
export default ProductAdd;