import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import moment from 'moment';
import './style.scss';
import './ekka.scss';
import SideBar from './sidebar';
import { Input,Form, Upload, Button ,Modal,DatePicker, Spin } from 'antd';
import Header from './Header';
import EditCategoryModal from './EditCategoryModal'; // Correct the import path
import EditPayment from "./EditPayment";
import { Select } from "antd";
const { Option } = Select;

const menuItems = [
	{ text: "Dashboard", icon: "mdi mdi-view-dashboard-outline", link: "index" },
	// Add more menu items as needed
  ];


  const MainCategory = () => {
	const token = localStorage.getItem('token');
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const handleToggleDropdown  = () => {
		setIsDropdownOpen(!isDropdownOpen);
	  };
	  const navigate = useNavigate();
	  const [data, setData] = useState([]);
	  const [loading, setLoading] = useState(true);
	  const [searchQuery, setSearchQuery] = useState("");
	  const [filteredData, setFilteredData] = useState([]);
	  const [companyCategories, setCompanyCategories] = useState([]);
	  const [expirationdate,setDate] = useState(null);
	  const [month,setMonth] = useState(null);
	  const [showEditModal, setShowEditModal] = useState(false); // State to manage the edit modal visibility
	  const [selectedCategory, setSelectedCategory] = useState(null); // State to store the selected category for editing
	  const [showPlanDurationModal, setShowPlanDurationModal] = useState(false);
	  const [addedit , setAddedit] = useState(null);

	  const handleTogglePlanDurationModal = (category) => {
		setShowPlanDurationModal(!showPlanDurationModal);
		setAddedit(category);
	  };
	  const handleSavePlanDuration = async () => {

		try {
			const confirmed = window.confirm("Are you sure you want to save changes?");
      if (!confirmed) {
        // If the user cancels, do nothing
        return;
      }

			console.log('Start of handleSavePlanDuration');
			const clientid = localStorage.getItem('selectedid');
			console.log('clientid:', clientid);
		  const response = await axios.post(
			'https://api.nagaratharcoc.com/api/v1/client/payment',
			{
			  clientid: clientid,
			  expirationdate: expirationdate,
			  month:parseInt(month),
			},
			{
			  headers: {
				'Content-Type': 'application/json',
				Authorization: `Bearer ${localStorage.getItem('token')}`,
			  },
			}
		  );
	  localStorage.removeItem('selectedid')
	  window.location.reload();
		  // Rest of the function remains unchanged...
		} catch (error) {
		  console.error('Error saving changes:', error);
		} finally {
		  handleTogglePlanDurationModal();
		}
	  };
	  
	  // Function to open the edit modal
	  const handleEditCategory = (category) => {
		setSelectedCategory(category);
		setShowEditModal(true);
	  };
	
	  // Function to close the edit modal
	  const handleCloseEditModal = () => {
		setSelectedCategory(null);
		setShowEditModal(false);
	  };
	  const handleSaveEdit = (editedData) => {
		// Handle the save logic here and update the categories array with the edited data
		// You can use the editedData to make an API call to save the changes
		console.log('Edited data:', editedData);
		// After saving, you can update the categories array and close the modal
		// For example, update the categories array and close the modal as follows:
		// setCategories((prevCategories) => {
		//   const updatedCategories = prevCategories.map((category) => {
		//     if (category.id === selectedCategory.id) {
		//       return { ...category, ...editedData };
		//     }
		//     return category;
		//   });
		//   return updatedCategories;
		// });
		handleCloseEditModal();
	  };
	  useEffect(() => {
		if (!token) {
		  // Redirect to the login page if the token is not present
		  navigate('/AdminLogin');
		  return;
		}
		fetchData();
	  }, []); // Empty dependency array ensures the effect runs only once
	  const fetchData = async () => {
		try {
		  const response = await axios.post("https://api.nagaratharcoc.com/api/v1/client/get",{},{
			
		  headers: {
			'Content-Type': 'application/json',
			Authorization: `Bearer ${localStorage.getItem('token')}`,
		  },
		  });
		  if (response.status === 401 || response.status === 404) {
			// Redirect to the login page for 401 or 404 errors
			navigate('/AdminLogin');
			return; // Exit early to prevent further logic execution
		  }
		  const clientIds = response.data.map((client) => client.clientid);
    localStorage.setItem("clientIds", JSON.stringify(clientIds));
		  setData(response.data);
		  setFilteredData(response.data);
		  setCompanyCategories(response.data);
		  setLoading(false);
		} catch (error) {
		  console.error("Error fetching data:", error);
		  setLoading(false);
		}
	  };

	  useEffect(() => {
		const filteredResults = data.filter((item) =>
		  (selectedCategory ? item.companycategory === selectedCategory : true) &&
		  ((item?.idno ?? '').toLowerCase().includes(searchQuery.toLowerCase()) ||
		  (item?.firstname ?? '').toLowerCase().includes(searchQuery.toLowerCase()) ||
		  (item?.companyname ?? '').toLowerCase().includes(searchQuery.toLowerCase()) ||
		  (item?.companycategory ?? '').toLowerCase().includes(searchQuery.toLowerCase()) ||
		  (item?.mobile ?? '').toLowerCase().includes(searchQuery.toLowerCase()) ||
		  (item?.paymentstatus ?? '').toLowerCase().includes(searchQuery.toLowerCase()) ||
		(item?.address1 ?? '').toLowerCase().includes(searchQuery.toLowerCase()))
		);
		setFilteredData(filteredResults);
	  }, [searchQuery, data, selectedCategory]);

	  // Function to determine the background color for the table row
const getRowBackgroundColor = (refereddetails) => {
	if (refereddetails && refereddetails.some((detail) => detail.accepted === true)) {
	  // If at least one refereddetail has accepted=true, return the green color
	  return '#b0e57c';
	}
	// Default color when no verification is done
	return '';
  };
  
return (
		<div className="wrapper">

		{/* <!-- LEFT MAIN SIDEBAR --> */}
		<SideBar/>


			{/* <!-- Header --> */}
			<Header/>

			{/* <!-- CONTENT WRAPPER --> */}
			<div className="ec-content-wrapper">
				<div className="content" style={{marginTop:"50px"}}>
					<div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
							<h1>Members</h1>
							<p className="breadcrumbs"><span><a href="index">Home</a></span>
								<span><i className="mdi mdi-chevron-right"></i></span>Members</p>
					</div>
					{loading ? (
                        <div style={{ textAlign: 'center', paddingTop: '50px' }}>
                            <Spin size="large" />
                        </div>
                    ) : (
					<div className="row">
						<div className="col-lg-12">
						<Select
            style={{ width: 200, marginBottom: 16 }}
            placeholder="Select Company Category"
            onChange={(value) => setSelectedCategory(value)}
          >
           {[...new Map(companyCategories.map(item => [item['companycategory'], item])).values()].map((category) => (
    <Option key={category.companycategoryid} value={category.companycategory}>
      {category.companycategory}
    </Option>
  ))}
          </Select>
			<input
              type="text"
              placeholder="Search..."
              value={searchQuery}
              onChange={(e) => setSearchQuery(e.target.value)}
              style={{marginBottom:"30px",marginLeft:"70%"}}
            />
							<div className="ec-cat-list card card-default">
								<div className="card-body">
									<div className="table-responsive">
                                    <table id="responsive-data-table" className="table">
											<thead>
												<tr>
													<th></th>
													<th>Member ID</th>
													<th>Name</th>
													 {/* <th>Email</th> */}
													<th>Mobile</th>
													<th>Company Name</th>
													<th>Company Category</th>
													<th>MemberType</th>
													 <th>Refered Details</th> 
													<th>Payment Status</th>
													<th>Plan Duration</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
  {Array.isArray(data) && filteredData.map((category) => (
    <tr key={category.clientid}>
		<td>
        {
  category.refereddetails && category.refereddetails.every((detail) => detail.accepted === true) ? (
    <span style={{ color: '#405DE6' }}>🔵</span>
  ) : (
    <span style={{ color: 'red' }}>🔴</span>
  )
}
      </td>
		<td>{category.idno}</td>
      <td>{category.firstname}</td>
      {/* <td>{category.email}</td> */}
      <td>{category.mobile}</td>
	  <td>{category.companyname}</td>
	  <td>{category.companycategory}</td>
      <td>
        {category.assignedmembertype.map((assignedType) => (
          <div key={assignedType.assignedmembertypeid}>
            {assignedType.membertype}
          </div>
        ))}
      </td>
	  <td>
  {category.refereddetails && category.refereddetails.map((referDetail) => (
    <div key={referDetail.referenceid}>
      {referDetail.name}({referDetail.companyname})
    </div>
  ))}
</td> 
<td>{category.paymentstatus}</td>
<td>
  <div className="btn-group">
  <a
  href="#"
  className="btn btn-outline-success"
  onClick={(e) => {
    e.preventDefault(); 
    // Log the selected clientid for debugging
    console.log('Selected clientid:', category.clientid);

    // Store the selected clientid in localStorage.clientIds
    const selectedClientId = category.clientid;
    const existingClientIds = JSON.parse(localStorage.getItem('clientIds')) || [];
    const updatedClientIds = [...new Set([...existingClientIds, selectedClientId])];
    localStorage.setItem('clientIds', JSON.stringify(updatedClientIds));
	localStorage.setItem('selectedid', category.clientid);
    // Toggle the plan duration modal
    handleTogglePlanDurationModal(category);
  }}
>
  View/Edit
</a>

  </div>
</td>


      <td>
        <div className="btn-group">
          <a
            href="#"
            className="btn btn-outline-success"
            onClick={(e) => {
              e.preventDefault(); 
              handleEditCategory(category);
            }}
          >
            Edit
          </a>
		  {/* <a
            href="#"
            className="btn btn-outline-success"
            onClick={(e) => {
              e.preventDefault(); 
            //   handleEditCategory(category);
            }}
          >
          </a> */}
        </div>
      </td>
    </tr>
  ))}
</tbody>

										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
					)}
				</div>
			</div> 


			{selectedCategory && (
  <EditCategoryModal
    category={selectedCategory}
    show={showEditModal} 
    onHide={handleCloseEditModal}
    onSave={handleSaveEdit}
	fetchData={fetchData}
  />
)}
{showPlanDurationModal && (
        <EditPayment

          visible={showPlanDurationModal}
          onCancel={handleTogglePlanDurationModal}
          onSave={handleSavePlanDuration}
          expirationdate={expirationdate}
          setDate={setDate}
          month={month}
          setMonth={setMonth}
		  setaddedit = {addedit}
        />
      )}

	</div>
  );
}

export default MainCategory;
