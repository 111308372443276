import React, { useState, useEffect } from 'react';
import axios from "axios";
import { useNavigate } from 'react-router-dom';
import './style.scss';
import './ekka.scss';
import SideBar from './sidebar';
import { Input,Form, Upload, Button } from 'antd';
import Header from './Header';
import { UploadOutlined } from '@ant-design/icons';
import EditPlanDuration from './EditPlanDuration';

const menuItems = [
	{ text: "Dashboard", icon: "mdi mdi-view-dashboard-outline", link: "index" },
	// Add more menu items as needed
  ];
  

  const CommonInfo = () => {
	const token = localStorage.getItem('token');
    const navigate = useNavigate();
	const [selectedCategory, setSelectedCategory] = useState(null); // State to store the selected category for editing
	const [showEditModal, setShowEditModal] = useState(false); // State to manage the edit modal visibility
	const [categoryList, setCategoriesList] = useState([]);

    //variables
    const [title, setTitle] =useState('');
    const [description, setDescription] =useState('');
    const [file, setUploadfiles] =useState([]); 
    const [fileList, setFileList] = useState([]); // To store uploaded files
    const [data, setData] = useState([]);
    const [loading, setLoading] = useState(true);
    const [iconimage, setIconimage] = useState('');
    //Dropdown
	const [isDropdownOpen, setIsDropdownOpen] = useState(false);
	const handleToggleDropdown  = () => {
		setIsDropdownOpen(!isDropdownOpen);
	  };
	
	  const handleCloseEditModal = () => {
		setSelectedCategory(null);
		setShowEditModal(false);
	  };
	  
	  // Function to open the edit modal
	  const handleEditCategory = (category) => {
		function getAllFileIds(category) {
			// Check if the 'files' property exists in the category object
			if (category && category.files && Array.isArray(category.files)) {
				// Map over the array and extract the fileId property
				return category.files.map(file => file.fileid);
			} else {
				// Handle the case where 'files' property is missing or not an array
				console.error("Invalid category object or missing 'files' property.");
				return [];
			}
		}
		setSelectedCategory(category);
		setShowEditModal(true);
	  };
	 
	  const handleFileUpload = async (options) => {
		const { onSuccess, onError, file, onProgress } = options;
		const formData = new FormData();
		formData.append("file", file);
	  
		try {
		  const response = await axios.post(
			"https://api.nagaratharcoc.com/api/v1/common/file",
			formData,
			{
			  onUploadProgress: (progressEvent) => {
				const percentCompleted = Math.round(
				  (progressEvent.loaded * 100) / progressEvent.total
				);
				onProgress({ percent: percentCompleted });
			  },
			  headers: {
				Authorization: 'Bearer ' + token,
				'Content-Type': 'multipart/form-data',
			  },
			}
		  );
	  
		  if (response.status === 401 || response.status === 404 || !token) {
			navigate('/login');
			return;
		  }
	  
		  if (response.status === 200) {
			const uploadedIconUrl = response.data.organiserId; // Assuming response.data contains the URL of the uploaded icon
			setIconimage(uploadedIconUrl); // Set the iconimage state with the uploaded icon URL as a string
			onSuccess("File uploaded");
		  } else {
			onError("File upload failed");
		  }
		} catch (error) {
		  onError("File upload failed");
		}
	  };
	  
      const onFinish = async (e) => {
		e.preventDefault(); // Prevent the default form submission behavior
		const token = localStorage.getItem('token');
		try {
		  setLoading(true);
		  // TODO: Replace with actual API call
		  const response = await fetch("https://api.nagaratharcoc.com/api/v1/common/add", {
			method: "POST",
			headers: {
			  'Authorization': 'Bearer ' + token,
			  'Content-Type': 'application/json',
			},
			body: JSON.stringify({
			  title,
			  description, 
              iconimage: iconimage,
			}),
		  });
	  
		  if (response.status === 401 || response.status === 404) { 
			// Redirect to the login page for 401 or 404 errors
			// navigate('/login');
			return; // Exit early to prevent further logic execution
		  }
	  
		  if (response.ok) {
			const data = await response.json();
			const token = data.token;
			console.log("POST request successful");
			if (response.data && response.data.length > 0) {
				setData(response.data);
			  } else {
				setData([]); // If no data is received, set an empty array
			  }
			setLoading(false);
			window.location.reload(); // Refresh the page
			if (data.token) {
			  localStorage.setItem('token', token);
			}
			// Optionally, you can update your component state or perform other actions here
		  } else {
			console.error("POST request failed");
		  }
		} catch (error) {
		  console.error("Error posting data:", error);
		  setLoading(false);
		}
	  };

      //Get API

      useEffect(() => {
		if (!token) {
		  // Redirect to the login page if the token is not present
		  navigate('/AdminLogin');
		  return;
		}
		fetchData();
	  }, []); // Empty dependency array ensures the effect runs only once
	  const fetchData = async () => {
		try {
		  const response = await axios.put("https://api.nagaratharcoc.com/api/v1/common/get",{},{
			
		  headers: {
			'Authorization': 'Bearer ' +token,
			'Content-Type': 'application/json',
		  },
		  });
		  if (response.status === 401 || response.status === 404) {
			// Redirect to the login page for 401 or 404 errors
			navigate('/login');
			return; // Exit early to prevent further logic execution
		  }
		  setCategoriesList(response.data);
		  setData(response.data);
		} catch (error) {
		  console.error("Error fetching data:", error);
		}
	  };

      //Delete API

      const handleDeleteCategory = async (commoninfoId) => {
		const confirmDelete = window.confirm("Are you sure you want to delete this social media icon?");

		if (!confirmDelete) {
		  // If the user cancels, do nothing
		  return;
		}
		try {
		  setLoading(true);
	  
		  // Make an API request to delete the category
		  const response = await axios.post(
			`https://api.nagaratharcoc.com/api/v1/common/delete`,
			{
                commoninfoid: commoninfoId, // Pass the categoryId as a payload
			},
			{
			  headers: {
				Authorization: 'Bearer ' + localStorage.getItem('token'),
				'Content-Type': 'application/json',
			  },
			}
		  );
		  if (response.status === 200) {
			// Refresh the page
			window.location.reload();
		  } else {
			console.error('Failed to delete Common Info');
		  }
	  
		  // Rest of the code remains the same
		  // ...
		} catch (error) {
		  console.error('Error deleting Common Info:', error);
		  setLoading(false);
		}
	  };


	const [isSubMenuOpen, setIsSubMenuOpen] = useState({
		vendors: false,
		user: false,
		categories: false,
		products: false,
		orders: false,
		authentication: false,
		icons: false,
		otherpages: false,
	  });
	  const toggleSubMenu = (menuName) => {
		setIsSubMenuOpen((prevState) => ({
		  ...prevState,
		  [menuName]: !prevState[menuName],
		}));
	  };
	  
	return (
		<div className="wrapper">

		{/* <!-- LEFT MAIN SIDEBAR --> */}
		<SideBar/>


			{/* <!-- Header --> */}
			<Header/>

			{/* <!-- CONTENT WRAPPER --> */}
			<div className="ec-content-wrapper">
				<div className="content" style={{marginTop:"50px"}}>
					<div className="breadcrumb-wrapper breadcrumb-wrapper-2 breadcrumb-contacts">
							<h1>Social Media Icons</h1>
							<p className="breadcrumbs"><span><a href="index">Home</a></span>
								<span><i className="mdi mdi-chevron-right"></i></span>Social Media Icons</p>
					</div>
					<div className="row">
						<div className="col-xl-4 col-lg-12">
							<div className="ec-cat-list card card-default mb-24px">
								<div className="card-body">
									<div className="ec-cat-form">
										<h4>Add New Social Media Icon</h4>

										<form
										onSubmit={onFinish} encType="multipart/form-data"
										>

											<div className="form-group row">
												<label for="text" className="col-12 col-form-label">Title</label> 
												<div className="col-12">
													<input id="text" name="text" className="form-control here slug-title" type="text" value={title} onChange={(e) => setTitle(e.target.value)} autoComplete="off" />
												</div>
											</div>

											<div className="form-group row">
												<label className="col-12 col-form-label">URL</label> 
												<div className="col-12">
													<input id="sortdescription" name="sortdescription"  className="form-control" value={description} onChange={(e) => setDescription(e.target.value)}></input>
												</div>
											</div> 
											<div className="col-md-12">
													<label className="form-label" name="file">Upload Icons</label>
													<Form.Item
															name="file"
														>
														<Upload
                                                        customRequest={handleFileUpload} 
                                                        fileList={fileList}
															onChange={({ fileList }) => setFileList(fileList)}>
															<Button icon={<UploadOutlined />} style={{top:'0px',borderRadius:'0px'}}>Click to Upload</Button>
														</Upload>
													</Form.Item> 
												</div>

											<div className="row">
												<div className="col-12">
													<button name="submit" type="submit" className="btn btn-primary">Submit</button>
												</div>
											</div>

										</form>

									</div>
								</div>
							</div>
						</div>
						<div className="col-xl-8 col-lg-12">
							<div className="ec-cat-list card card-default">
								<div className="card-body">
									<div className="table-responsive">
                                    <table id="responsive-data-table" className="table">
											<thead>
												<tr>
													{/* <th>Thumb</th> */}
													<th>Title</th>
													<th>URL</th>
													<th>Icons</th>
													<th>Action</th>
												</tr>
											</thead>
											<tbody>
                        {data.map((cinfo) => (
                          <tr key={cinfo.commoninfoid}>
                            <td>{cinfo.title}</td>
                            <td>{cinfo.description}</td>
                            <td>
        {cinfo.iconimage ? (
          <img src={cinfo.iconimage} alt="Icon" width="100" height="100" />
        ) : (
          "No image available"
        )}
      </td>
                            <td>
							<div className="btn-group">
   <a
      href="#"
      className="btn btn-outline-success"
      onClick={(e) => {
        e.preventDefault();
        handleEditCategory(cinfo);
      }}
    >
      Edit
    </a> 
     <a
      href="#"
      className="btn btn-outline-danger"
      onClick={(e) => {
        e.preventDefault(); 
        handleDeleteCategory(cinfo.commoninfoid);
      }}
    >
      Delete
    </a> 
  </div>
                            </td>
                          </tr>
                        ))}
                      </tbody>

										</table>
									</div>
								</div>
							</div>
						</div>
					</div>
				</div>
				{selectedCategory && (
  <EditPlanDuration
  show={showEditModal}
  onHide={handleCloseEditModal}
  categoryList={categoryList}
  category={selectedCategory}
  handleFileUpload={handleFileUpload}
/>
)}	
			</div> 


	</div>
  );
}

export default CommonInfo;
